export const variantPages = {
  initial: {
    y: -15,
    opacity: 0,
  },
  animate: {
    y: 0,
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: [0.05, 0.41, 0.24, 0.95],
    },
  },
  exit: {
    x: 5,
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: [0.42, 0.11, 0.51, 0.9],
    },
  },
};
