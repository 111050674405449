export const indexSEO = {
  title: "Уборка квартир и химчистка | Cleanteam",
  description: "Уборка квартир и химчистка любой сложности в Красноярске.",
  keywords:
    "клинтим, клинтим красноярск, уборка квартир, уборка и химчика, уборка квартир в Красноярске, химчистка в Красноярске, экспресс уборка, уборка после ремонта, генеральная уборка, генеральная уборка кухни, клининг компании красноярска",
  themeColor: "white",
  slug: "/",
  preview: "/preview.jpg",
};

export const drycleaningSEO = {
  title: "Химчистка | Cleanteam",
  description: "Химчистка в Красноярске.",
  keywords:
    "химчистка, химчистка в Красноярске, уборка квартир, уборка и химчика, уборка квартир в Красноярске, химчистка в Красноярске, экспресс уборка, уборка после ремонта, генеральная уборка, генеральная уборка кухни, клининг компании красноярска",
  themeColor: "white",
  slug: "/drycleaning",
  preview: "/preview.jpg",
};

export const expresscleaningSEO = {
  title: "Экспресс уборка | Cleanteam",
  description: "Экспресс уборка в Красноярске.",
  keywords:
    "экспресс уборка, экспресс уборка в Красноярске, уборка квартир, уборка и химчика, уборка квартир в Красноярске, химчистка в Красноярске, экспресс уборка, уборка после ремонта, генеральная уборка, генеральная уборка кухни, клининг компании красноярска",
  themeColor: "white",
  slug: "/expresscleaning",
  preview: "/preview.jpg",
};

export const cleaningafterrenovationSEO = {
  title: "Уборка после ремонта | Cleanteam",
  description: "Уборка после ремонта в Красноярске.",
  keywords:
    "уборка после ремонта, уборка после ремонта в Красноярске, уборка квартир, уборка и химчика, уборка квартир в Красноярске, химчистка в Красноярске, экспресс уборка, уборка после ремонта, генеральная уборка, генеральная уборка кухни, клининг компании красноярска",
  themeColor: "white",
  slug: "/cleaningafterrenovation",
  preview: "/preview.jpg",
};

export const springcleaningSEO = {
  title: "Генеральная уборка | Cleanteam",
  description: "Генеральная уборка в Красноярске.",
  keywords:
    "генеральная уборка, генеральная уборка в Красноярске, уборка квартир, уборка и химчика, уборка квартир в Красноярске, химчистка в Красноярске, экспресс уборка, уборка после ремонта, генеральная уборка, генеральная уборка кухни, клининг компании красноярска",
  themeColor: "white",
  slug: "/springcleaning",
  preview: "/preview.jpg",
};

export const generalcleaningkitchenSEO = {
  title: "Генеральная уборка кухни | Cleanteam",
  description: "Генеральная уборка кухни в Красноярске.",
  keywords:
    "генеральная уборка кухни, генеральная уборка кухни в Красноярске, уборка квартир, уборка и химчика, уборка квартир в Красноярске, химчистка в Красноярске, экспресс уборка, уборка после ремонта, генеральная уборка, генеральная уборка кухни, клининг компании красноярска",
  themeColor: "white",
  slug: "/generalcleaningkitchen",
  preview: "/preview.jpg",
};

export const dailycleaningSEO = {
  title: "Повседневная уборка квартиры | Cleanteam",
  description: "Повседневная уборка квартиры в Красноярске.",
  keywords:
    "повседневная уборка квартиры, повседневная уборка квартиры в Красноярске, уборка квартир, уборка и химчика, уборка квартир в Красноярске, химчистка в Красноярске, экспресс уборка, уборка после ремонта, генеральная уборка, генеральная уборка кухни, клининг компании красноярска",
  themeColor: "white",
  slug: "/dailycleaning",
  preview: "/preview.jpg",
};

export const windowsandbalconiesSEO = {
  title: "Окна и балконы | Cleanteam",
  description: "Мытье окон и балконов в Красноярске.",
  keywords:
    "мытье окон и балконов, мытье окон и балконов в Красноярске, уборка квартир, уборка и химчика, уборка квартир в Красноярске, химчистка в Красноярске, экспресс уборка, уборка после ремонта, генеральная уборка, генеральная уборка кухни, клининг компании красноярска",
  themeColor: "white",
  slug: "/windowsandbalconies",
  preview: "/preview.jpg",
};

export const additionalservicesSEO = {
  title: "Дополнительные услуги | Cleanteam",
  description: "Дополнительные услуги химчистки в Красноярске.",
  keywords:
    "уборка квартир, уборка и химчика, уборка квартир в Красноярске, химчистка в Красноярске, экспресс уборка, уборка после ремонта, генеральная уборка, генеральная уборка кухни, клининг компании красноярска",
  themeColor: "white",
  slug: "/additionalservices",
  preview: "/preview.jpg",
};

export const aboutSEO = {
  title: "О нас | Cleanteam",
  description: "Как мы работаем? С кем мы сотрудничаем?",
  keywords:
    "клинтим, клинтим красноярск, уборка квартир, уборка и химчика, уборка квартир в Красноярске, химчистка в Красноярске, экспресс уборка, уборка после ремонта, генеральная уборка, генеральная уборка кухни, клининг компании красноярска",
  themeColor: "white",
  slug: "/about",
  preview: "/preview.jpg",
};

export const infoSEO = {
  title: "Полезная информация | Cleanteam",
  description: "Часто задаваемые вопросы",
  keywords:
    "клинтим, клинтим красноярск, уборка квартир, уборка и химчика, уборка квартир в Красноярске, химчистка в Красноярске, экспресс уборка, уборка после ремонта, генеральная уборка, генеральная уборка кухни, клининг компании красноярска",
  themeColor: "white",
  slug: "/info",
  preview: "/preview.jpg",
};

export const subscriptionSEO = {
  title: "Абонементы | Cleanteam",
  description: "Абонементы на уборку и химчистку в CleanTeam",
  keywords:
    "клинтим, клинтим красноярск, уборка квартир, уборка и химчика, уборка квартир в Красноярске, химчистка в Красноярске, экспресс уборка, уборка после ремонта, генеральная уборка, генеральная уборка кухни, клининг компании красноярска",
  themeColor: "white",
  slug: "/subscription",
  preview: "/preview.jpg",
};

export const certificatesSEO = {
  title: "Сертификаты | Cleanteam",
  description: "Сертификаты на уборку и химчистку в CleanTeam",
  keywords:
    "клинтим, клинтим красноярск, уборка квартир, уборка и химчика, уборка квартир в Красноярске, химчистка в Красноярске, экспресс уборка, уборка после ремонта, генеральная уборка, генеральная уборка кухни, клининг компании красноярска",
  themeColor: "white",
  slug: "/certificates",
  preview: "/preview.jpg",
};
