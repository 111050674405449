import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const MetaTag = ({ data }) => {
  const { site } = useStaticQuery(query);
  const { siteUrl } = site?.siteMetadata;
  const { title, description, keywords, themeColor, slug, preview } = data;

  return (
    <Helmet title={title}>
      <meta name="description" content={description} />
      <meta name="image" content={`${siteUrl}${preview}`} />
      <meta name="keywords" content={keywords} />
      <meta name="theme-color" content={themeColor} />
      <meta property="og:url" content={`${siteUrl}${slug}`} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${siteUrl}${preview}`} />
      <script type="text/javascript">
        {`(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
              m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
              (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
              ym(88058343, "init", {
              clickmap:true,
              trackLinks:true,
              accurateTrackBounce:true,
              webvisor:true
            });`}
      </script>
      <noscript>
        {`
        <div>
          <img
            src="https://mc.yandex.ru/watch/88058343"
            style="position:absolute; left:-9999px;"
            alt=""
          />
        </div>`}
      </noscript>
    </Helmet>
  );
};

export default MetaTag;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
